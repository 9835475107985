import axios from "axios";

export const MINT_TOKEN_HASH = 'oneOffs/MINT_TOKEN_HASH'
export const MINT_TOKEN_RESULT = 'oneOffs/MINT_TOKEN_RESULT'
export const GOT_TOKEN_DATA = 'oneOffs/GOT_TOKEN_DATA'
export const GOT_IPFS_METADATA = 'oneOffs/GOT_IPFS_METADATA'
export const PURCHASE_TOKEN_TX_HASH = 'oneOffs/PURCHASE_TOKEN_TX_HASH'
export const PURCHASE_TOKEN_TX_RESULT = 'oneOffs/PURCHASE_TOKEN_TX_RESULT'
export const CLEAR_BUY_TOKEN_TX_HASH = 'oneOffs/CLEAR_BUY_TOKEN_TX_HASH'

export const SET_ALL_TOKEN_DATA = 'oneOffs/SET_ALL_TOKEN_DATA'

const initialState = {
    tokenData: null,
    buyTxHash: null
}

export const getAvailableTokensData = () => {
    return async (dispatch, getState) => {
        // const state = getState()

        let data = {
            'K-Compositions Collage': {
                tokenIndex: 0,
                tokenMetadataUri: 'ipfs://QmVqZKEChnyUDR3gi5jZgYnaW7B2XDzoPh1nitsRzvREvW',
                availableForPurchase: false,
                tokenOwner: '0xCe4c8A44881D290865598Efb51C1A05eCb8A8324',
                tokenName: "K-Compositions Collage",
                tokenPrice: "10000000000000000000",
                imgWidth: "3750",
                imgHeigh: "1500",
                imgType: "svg"
            },
            'Lino 2': {
                tokenIndex: 1,
                tokenMetadataUri: "ipfs://QmZgoioAymbwysVbHt5HvmP75pxsMNZRnQBJ2uGuk8Z48d",
                availableForPurchase: false,
                tokenOwner: "0x1a9fEB821e304892A646C64f0D7bb16F1538e48d",
                tokenName: "Lino 2",
                tokenPrice: "1000000000000000000",
                imgWidth: "2400",
                imgHeigh: "2400",
                imgType: "png"

            },
            'Lino 4': {
                tokenIndex: 2,
                tokenMetadataUri: "ipfs://QmRFzuACtAd1ZJjC5B2utuqAKbrLEfQ21Zt8pe1w5FeyZx",
                availableForPurchase: false,
                tokenOwner: "0xCe4c8A44881D290865598Efb51C1A05eCb8A8324",
                tokenName: "Lino 4",
                tokenPrice: "2000000000000000000",
                imgWidth: "2400",
                imgHeigh: "2400",
                imgType: "png"
            },
            'Mutations 3': {
                tokenIndex: 3,
                tokenMetadataUri: "ipfs://QmfQCoQfgAdDWEJc5nJAWaMB5vAq5287A5aeNXpeJ6M3Ed",
                availableForPurchase: false,
                tokenOwner: "0x6B67623ff56c10d9dcFc2152425f90285fC74DDD",
                tokenName: "Mutations 3",
                tokenPrice: "2000000000000000000",
                imgWidth: "2400",
                imgHeigh: "2400",
                imgType: "png"
            },
            'Mutations 5': {
                tokenIndex: 4,
                tokenMetadataUri: "ipfs://QmcsgJpu6GL9gqDG4Rq9CwB27xFTiRZ95EAPMUjv4qeE2X",
                availableForPurchase: false,
                tokenOwner: "0x6B67623ff56c10d9dcFc2152425f90285fC74DDD",
                tokenName: "Mutations 5",
                tokenPrice: "1000000000000000000",
                imgWidth: "2400",
                imgHeigh: "2400",
                imgType: "png"
            },
            'Finality 1': {
                tokenIndex: 5,
                tokenMetadataUri: "ipfs://Qmf2TgQvdYsaj7BMXpmodHmc1vFMhrT8mFc2NXay3TMyyn",
                availableForPurchase: false,
                tokenOwner: "0xAB6cA2017548A170699890214bFd66583A0C1754",
                tokenName: "Finality 1",
                tokenPrice: "2000000000000000000",
                imgWidth: "180",
                imgHeigh: "180",
                imgType: "svg"
            },
            'Finality 2': {
                tokenIndex: 6,
                tokenMetadataUri: "ipfs://QmcRL9te51uYCmknjdeTd8nV1uBusCZ4Vqcd9qthn2LtBE",
                availableForPurchase: false,
                tokenOwner: "0xA994EbD5Bd30Fe24bE33a153B12A4e8d56B54a66",
                tokenName: "Finality 2",
                tokenPrice: "2000000000000000000",
                imgWidth: "180",
                imgHeigh: "180",
                imgType: "svg"
            }
        }

        dispatch({
            type: SET_ALL_TOKEN_DATA,
            tokens: data
        })

        // let tokenId = await state.app.gener8tiveOneOffsERC721Contract.methods.tokenId().call()
        // // console.log(state.app.gener8tiveOneOffsERC721Contract)

        // for (let i = 0; i < tokenId; i++) {
        //     let tokenData = await state.app.gener8tiveOneOffsERC721Contract.methods.getTokenData(i).call()

        //     // console.log('got token data', tokenData)

        //     dispatch({
        //         type: GOT_TOKEN_DATA,
        //         tokenData: {
        //             tokenIndex: i,
        //             tokenMetadataUri: tokenData.tokenMetadataUri,
        //             availableForPurchase: tokenData.availableForPurchase,
        //             tokenOwner: tokenData.tokenOwner,

        //             tokenName: tokenData.data.name,
        //             tokenPrice: tokenData.data.price,
        //             imgWidth: tokenData.data.width,
        //             imgHeigh: tokenData.data.height,
        //             imgType: tokenData.data.imgType
        //         }
        //     })
        // }
    }
}

export const getIpfsMetadata = (tokenData) => {
    return async (dispatch, getState) => {
        // const state = getState()

        // console.log('getIpfsMetdata', tokenData)

        let ipfsPath = tokenData.tokenMetadataUri.split('ipfs://').join('')
        let gatewayUrl = `https://gener8tive.mypinata.cloud/ipfs/${ipfsPath}` 

        axios.get(gatewayUrl)
        .then(function (response) {
            // console.log('got response from ipfs', response.data)
            dispatch({
                type: GOT_IPFS_METADATA,
                ipfsMetadata: response.data
            })
        })
    }
}

export const mint = (price) => {
    return async (dispatch, getState) => {
        const state = getState()

        state.app.gener8tiveOneOffsERC721Contract.methods.mint(price)
        .send({ from: state.app.accounts[0] })
        .on('transactionHash', (hash) => {
            console.log(`mint transactionHash: ${hash}`)
            dispatch({
                type: MINT_TOKEN_HASH,
                txHash: hash
            })
        })
        .then((result) => {
            console.log('mint result:', result)
            dispatch({
                type: MINT_TOKEN_RESULT,
                txResult: result
            })
        })
    }
}

export const purchaseToken = (tokenData) => {
    return async (dispatch, getState) => {
        const state = getState()

        state.app.gener8tiveOneOffsERC721Contract.methods.purchaseToken(tokenData.tokenIndex)
        .send({ from: state.app.accounts[0], value: tokenData.tokenPrice })
        .on('transactionHash', (hash) => {
            console.log('purchaseToken transactionHash', hash)
            dispatch({
                type: PURCHASE_TOKEN_TX_HASH,
                txHash: hash
            })
        })
        .then((result) => {
            console.log('purchaseToken result', result)
            dispatch({
                type: PURCHASE_TOKEN_TX_RESULT,
                txResult: result
            })
        })
    }
}

export const clearTxWait = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: CLEAR_BUY_TOKEN_TX_HASH
        })
    }
}

const moduleActions = (state = initialState, action) => {
    switch (action.type) {
        case MINT_TOKEN_HASH:
            return {
                ...state,
                mintTxHash: action.txHash
            }

        case MINT_TOKEN_RESULT:
            return {
                ...state,
                mintTxHash: null
            }
        
        case SET_ALL_TOKEN_DATA:
            state.tokenData = action.tokens

            return {
                ...state
            }
        
        case GOT_TOKEN_DATA:
            if(!state.tokenData) {
                state.tokenData = {}
            }

            // console.log('action.tokenData', action.tokenData)

            state.tokenData[action.tokenData.tokenName] = action.tokenData

            return {
                ...state
            }

        case GOT_IPFS_METADATA:
            // console.log('state', state.tokeNdata)
            // console.log('GOT_IPFS_METADATA', action.ipfsMetadata)

            state.tokenData[action.ipfsMetadata.name].ipfsMetadata = action.ipfsMetadata

            return {
                ...state
            }
        
        case PURCHASE_TOKEN_TX_HASH:
            return {
                ...state,
                buyTxHash: action.txHash
            }

        case PURCHASE_TOKEN_TX_RESULT:
            return {
                ...state,
                buyTxHash: null
            }

        case CLEAR_BUY_TOKEN_TX_HASH:
            return {
                ...state,
                buyTxHash: null
            }

        default:
            return state
    }
}

export default moduleActions
