import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'

// import getWeb3 from "../utils/getWeb3"
// import getWeb3Provider from "../utils/getWeb3Provider"

// import Gener8tiveMetroSeriesERC721 from '../contracts/metro/MetroSeriesERC721.json'

import {
    AXIOS_CACHE_MAX_AGE,
    // REACT_GA_ENABLED,
    MINT_STATUS_API
    // MUTATIONS_LOCAL_NETWORK
} from '../gener8tiveConstants'

// import ReactGA from 'react-ga'

import { GOT_MINT_STATUS } from './metro'

export const INIT_APP_COMPLETE = 'app/INIT_APP_COMPLETE'
export const INIT_WEB3_COMPLETE = 'app/INIT_WEB3_COMPLETE'

const initialState = {
    web3: null,
    accounts: null,
    selectedNetwork: null,
    web3Provider: null,
    Gener8tiveMetroSeriesERC721Contract: null,
    // api: null,
    error: null
}

export const initApp = () => {
    return async (dispatch, getState) => {
        const cache = setupCache({
            maxAge: AXIOS_CACHE_MAX_AGE
        })
      
        const api = axios.create({
            adapter: cache.adapter
        })

        axios.post(MINT_STATUS_API, { seriesId: 1 })
        .then(function (response) {
            // console.log('got response from servicestatus api', response)
            dispatch({
                type: GOT_MINT_STATUS,
                data: response.data
            })
        })

        dispatch({
            type: INIT_APP_COMPLETE,
            api: api
        })
    }
}

// export const initWeb3 = () => {
//     return async (dispatch, getState) => {
//         let web3
//         let web3Provider
//         let accounts
//         let networkId
//         let network
        
//         try {
//             web3 = await getWeb3()

//             web3Provider = await getWeb3Provider(web3)
//             console.log(`web3 provider: ${web3Provider}`)

//             accounts = await web3.eth.getAccounts()
//             console.log(`accounts:  ${accounts}`)

//             networkId = await web3.eth.net.getId()
//             console.log(`networkId: ${networkId}`)

//             network = await web3.eth.net.getNetworkType()
//             console.log(`network: ${network}`)

//             if(REACT_GA_ENABLED) {
//                 ReactGA.event({
//                     category: 'Web3',
//                     action: 'ProviderSet-' + web3Provider,
//                 })
//             }
//         }
//         catch(e) {
//             web3 = null
//             console.log('initWeb3 - web3 = null')
//             if(REACT_GA_ENABLED) {
//                 ReactGA.exception({
//                     description: 'initWeb3 - web3 = null',
//                     fatal: false
//                 })
//             }
//             dispatch({
//                 type: INIT_WEB3_COMPLETE,
//                 error: (web3 ? 'web3 OK' : 'web3 undefined') + ':' + e.toString(),
//                 web3Provider: web3Provider,
//                 accounts: accounts,
//                 web3: web3
//             })
//             return
//         }

//         if(!networkId) {
//             console.log('initWeb3 - undefined_network_id')
//             if(REACT_GA_ENABLED) {
//                 ReactGA.exception({
//                     description: 'initWeb3 - undefined_network_id',
//                     fatal: false
//                 })
//             }
//             dispatch({
//                 type: INIT_WEB3_COMPLETE,
//                 error: 'undefined_network_id'
//             })
//             return
//         }
          
//         if(!accounts) {
//             console.log('initWeb3 - undefined_accounts')
//             if(REACT_GA_ENABLED) {
//                 ReactGA.exception({  
//                     description: 'initWeb3 - undefined_accounts',
//                     fatal: false
//                 })
//             }
//             dispatch({
//                 type: INIT_WEB3_COMPLETE,
//                 error: 'undefined_accounts'
//             })
//             return
//         }

//         if(REACT_GA_ENABLED) {
//             ReactGA.set({ userId: accounts[0] })
//         }

//         console.log('networkId', networkId)
      
//         const gener8tiveMetroSeriesERC721DeployedNetwork = Gener8tiveMetroSeriesERC721.networks[networkId]
          
//         // contract not deployed on current web3 provided network
//         // if(!gener8tiveKBlocksERC721DeployedNetwork) {
//         //     //TODO not good
//         //     console.log('initWeb3 - network_mismatch')
//         //     if(REACT_GA_ENABLED) {
//         //         ReactGA.exception({
//         //             description: 'initWeb3 - network_mismatch',
//         //             fatal: false
//         //         })
//         //     }
//         //     dispatch({
//         //         type: INIT_WEB3_COMPLETE,
//         //         error: 'network_mismatch',
//         //         selectedNetwork: network,
//         //         web3Provider: web3Provider,
//         //         dappNetworks: Gener8tiveKBlocksERC721.networks,
//         //     })
//         //     return
//         // }

//         const gener8tiveMetroSeriesERC721Contract = new web3.eth.Contract(
//             Gener8tiveMetroSeriesERC721.abi,
//             gener8tiveMetroSeriesERC721DeployedNetwork && gener8tiveMetroSeriesERC721DeployedNetwork.address
//         )

//         dispatch({
//             type: INIT_WEB3_COMPLETE,
//             web3: web3,
//             accounts: accounts,
//             selectedNetwork: network,
//             web3Provider: web3Provider,
//             gener8tiveMetroERC721Contract: gener8tiveMetroSeriesERC721Contract,
//             gener8tiveMetroERC721ContractAddress: gener8tiveMetroSeriesERC721Contract.address,
//           })
//     }
// }

const moduleActions = (state = initialState, action) => {
    switch (action.type) {
      case INIT_APP_COMPLETE:
        return {
            ...state,
            // api: action.api
        }

    case INIT_WEB3_COMPLETE:
        return {
            ...state,
            web3: action.web3,
            accounts: action.accounts,
            selectedNetwork: action.selectedNetwork,
            web3Provider: action.web3Provider,
            error: action.error,
            dappNetworks: action.dappNetworks,
            gener8tiveMetroSeriesERC721Contract: action.gener8tiveMetroSeriesERC721Contract,
            gener8tiveMetroSeriesERC721ContractAddress: action.gener8tiveMetroSeriesERC721ContractAddress,
            api: action.api
        }

        default:
            return state
    }
}

export default moduleActions
