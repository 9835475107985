import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import { Twitter } from 'mdi-material-ui'

import { Icon } from '@iconify/react';
import discordIcon from '@iconify-icons/mdi/discord';


import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    appBar: {
        // paddingTop: "25px",
        boxShadow: "none",
        height: 80,
        // display: "flex",
        border: "0",
        padding: "0.625rem 0",
        marginBottom: "20px",
        width: "100%",
        backgroundColor: "transparent !important",
        transition: "all 1000ms ease 0.2s",
        // alignItems: "center",
        // flexFlow: "row nowrap",
        // justifyContent: "flex-start",
        // position: "relative",
        // zIndex: "unset"
    },
    appBarScrolled: {
        // paddingTop: "25px",
        height: 80,
        // display: "flex",
        border: "0",
        padding: "0.625rem 0",
        marginBottom: "20px",
        width: "100%",
        backgroundColor: "#fff",
        // backgroundColor: "transparent !important",
        boxShadow: "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
        transition: "all 150ms ease 0s",
        // alignItems: "center",
        // flexFlow: "row nowrap",
        // justifyContent: "flex-start",
        // position: "relative",
        // zIndex: "unset"
    },
    socialBtn: {
        // boxShadow: "none"
    },
    discordBtn: {
        fontSize: '24px'
    }
})

class AppHeader extends Component {
    state = { useScrolledHeader: false }

    componentDidMount() {
        if (this.props.changeOnScroll) {
            window.addEventListener("scroll", this.onHeaderScroll)
        }
    }

    componentWillUnmount() {
        if (this.props.changeOnScroll) {
            window.removeEventListener("scroll", this.onHeaderScroll)
        }
    }

    onHeaderScroll = () => {
        const windowsScrollTop = window.pageYOffset

        if(windowsScrollTop > 60) {
            this.setState({ useScrolledHeader: true })
        }
        else {
            this.setState({ useScrolledHeader: false })
        }
    }

    render() {
        const { classes, leftLinks } = this.props
        return (
            <AppBar className={ this.state.useScrolledHeader ? classes.appBarScrolled : classes.appBar }>
                <Toolbar>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item>
                            { leftLinks ? leftLinks : null }
                        </Grid>
                        <Grid item>
                            <Button
                                // variant='fab'
                                // mini={true}
                                className={classes.discordBtn}
                                href="https://discord.gg/Czq4zYS3Ew"
                                target="_blank"
                            >
                                <Icon icon={discordIcon} />
                            </Button>
                            <Button
                                // variant='fab'
                                mini={true}
                                className={classes.socialBtn}
                                href="https://twitter.com/gener8tive"
                                target="_blank"
                            >
                                <Twitter/>
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

AppHeader.contextTypes = {
    router: PropTypes.object,
    store: PropTypes.object
}
  
const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
    // market: state.market
})
    
const mapDispatchToProps = dispatch => bindActionCreators({
    // initWeb3
}, dispatch)
    
    
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withStyles(styles)(AppHeader))
