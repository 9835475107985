import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import app from './app'
import kComps from './kComps'
import mutations from './mutations'
import oneOfffs from './oneOffs'
import tones from './tones'
import metro from './metro'

const reducer = (history) => combineReducers({
  router: connectRouter(history),
  app: app,
  kComps: kComps,
  mutations: mutations,
  oneOffs: oneOfffs,
  tones: tones,
  metro: metro
})

export default reducer
