import axios from "axios";

export const GOT_IPFS_METADATA = 'tones/GOT_IPFS_METADATA'

const initialState = {
    tokenMetadata: [],
}

export const getToneMetadata = (tokenId, metadataUrl) => {
    return async (dispatch, getState) => {
        // const state = getState()

        axios.get(metadataUrl)
        .then(function (response) {
            console.log(response.data)
            dispatch({
                type: GOT_IPFS_METADATA,
                ipfsMetadata: response.data,
                tokenId: tokenId
            })
        })
    }
}

const moduleActions = (state = initialState, action) => {
    switch (action.type) {
        
        case GOT_IPFS_METADATA:
            state.tokenMetadata[action.tokenId] = action.ipfsMetadata
            return {
                ...state,
            }

        default:
            return state
    }
}

export default moduleActions
