import axios from "axios";

import {
    MUTATIONS_METADATA_URL_BASE
} from '../gener8tiveConstants'

export const GOT_SUPPLY_DATA = 'mutations/GOT_SUPPLY_DATA'
export const GOT_TOKEN_METADATA = 'mutations/GOT_TOKEN_METADATA'
export const CLEAR_TOKEN_METADATA = 'mutations/CLEAR_TOKEN_METADATA'

const initialState = {
    supplyData: null,
    tokenMetadata: null
}

export const getSupplyData = () => {
    return async (dispatch) => {

        let tmpSupplyData = {
            cBeneficiary: '0xc7464dbcA260A8faF033460622B23467Df5AEA42',
            supplyDataPrice: '175000000000000000',
            supplyDataMaxSupply: 256,
            feePercentage: 75,
            mintedTokens: 257,
            lastTokenIndex: 256
        }

        dispatch({
            type: GOT_SUPPLY_DATA,
            ...tmpSupplyData
        })
    }
}

export const getTokenMetadata = (tokenId) => {
    return async (dispatch) => {
        axios.get(`${MUTATIONS_METADATA_URL_BASE}/${tokenId}`)
        .then(function (response) {
            console.log('got response from metadata api', response)
            dispatch({
                type: GOT_TOKEN_METADATA,
                metadata: response.data
            })
        })
    }
}

export const clearTokenMetadata = () => {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_TOKEN_METADATA
        })
    }
}

const moduleActions = (state = initialState, action) => {
    switch (action.type) {
        case GOT_SUPPLY_DATA:
            return {
                ...state,
                supplyData: {
                    causeBeneficiary: action.cBeneficiary,
                    price: action.supplyDataPrice,
                    feePercentage: action.feePercentage,
                    mintedTokens: action.mintedTokens,
                    lastTokenIndex: action.lastTokenIndex,
                    maxSupply: action.supplyDataMaxSupply
                }
            }

        case GOT_TOKEN_METADATA:
            return {
                ...state,
                tokenMetadata: action.metadata
            }
        
        case CLEAR_TOKEN_METADATA:
            return {
                ...state,
                tokenMetadata: null
            }

        default:
            return state
    }
}

export default moduleActions
