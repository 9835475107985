export function upperCaseFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function networIdToName(id) {
  switch (id) {
    case 1:
      return 'Main'

    case 2:
      return 'Morden'
    
    case 3:
      return 'Ropsten'

    case 4:
      return 'Rinkeby'

    case 42:
      return 'Kovan'
  
    default:
      return 'Private'
  }
}

export function listNetworksInContractNetworks(contractNetworks) {
  // console.log(contractNetworks)
  let networkNames= []
  Object.keys(contractNetworks).forEach((obj) => {
    let networkName = networIdToName(parseInt(obj))
    if(networkName !== 'Private') {
      networkNames.push(networkName)
    }
  })
  return networkNames.join(' & ')
}