import axios from "axios";

import {
    KCOMPS_METADATA_URL_BASE
} from '../gener8tiveConstants'

// export const GOT_SUPPLY_DATA = 'kComps/GOT_SUPPLY_DATA'
// export const GOT_SHOWCASE_ITEM_INSTRUCTIONS = 'kComps/GOT_SHOWCASE_ITEM_INSTRUCTIONS'
export const CLEAR_TOKEN_METADATA = 'kComps/CLEAR_TOKEN_METADATA'
export const GOT_TOKEN_METADATA = 'kComps/GOT_TOKEN_METADATA'

const initialState = {
    // drawData: null,
    // supplyData: null,
    tokenMetadata: null
}

// export const getSupplyData = () => {
//     return async (dispatch, getState) => {
//         const state = getState()

//         var supplyData

//         if(state.app.web3Provider === 'infura') {
//             supplyData = await state.app.gener8tiveKBlocksERC721Contract.methods.getSupplyData('0x0000000000000000000000000000000000000000').call()
//         }
//         else {
//             supplyData = await state.app.gener8tiveKBlocksERC721Contract.methods.getSupplyData(state.app.accounts[0]).call()
//         }

//         dispatch({
//             type: GOT_SUPPLY_DATA,
//             ...supplyData
//         })
//     }
// }

// export const getShowcaseItemDrawInstructions = (compositionId) => {
//     return async (dispatch, getState) => {
//         const state = getState()

//         var drawData = await state.app.gener8tiveKBlocksERC721Contract.methods.getTokenData(compositionId).call()

//         dispatch({
//             type: GOT_SHOWCASE_ITEM_INSTRUCTIONS,
//             compositionId: compositionId,
//             compositionDrawData: drawData
//         })
//     }
// }

export const getShowcaseItemMetadata = (compositionId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: CLEAR_TOKEN_METADATA
        })
        axios.get(`${KCOMPS_METADATA_URL_BASE}/${compositionId}`)
        .then(function (response) {
            console.log('got response from metadata api', response)
            dispatch({
                type: GOT_TOKEN_METADATA,
                metadata: response.data
            })
        })
    }
}

const moduleActions = (state = initialState, action) => {
    switch (action.type) {
        // case GOT_SUPPLY_DATA:
        //     return {
        //         ...state,
        //         supplyData: {
        //             supplyDataCauseBeneficiary: action.supplyDataCauseBeneficiary,
        //             supplyDataCreatorSupply: action.supplyDataCreatorSupply,
        //             supplyDataFeePercentage: action.supplyDataFeePercentage,
        //             supplyDataMaxSupply: action.supplyDataMaxSupply,
        //             supplyDataPrice: action.supplyDataPrice,
        //             supplyDataTotalSupply: action.supplyDataTotalSupply,
        //             supplyDataOwnedTokens: action.ownedTokens
        //         }
        //     }
        
        // case GOT_SHOWCASE_ITEM_INSTRUCTIONS:
        //     if(state.drawData) {
        //         let dd = state.drawData
        //         dd[action.compositionId] = action.compositionDrawData
        //         return {
        //             ...state
        //         }
        //     }
        //     else {
        //         return {
        //             ...state,
        //             drawData: { [action.compositionId]: action.compositionDrawData }
        //         }
        //     }

        case CLEAR_TOKEN_METADATA:
            return {
                ...state.tokenMetadata,
                tokenMetadata: null
            }

        case GOT_TOKEN_METADATA:
            return {
                ...state,
                tokenMetadata: action.metadata
            }

        default:
            return state
    }
}

export default moduleActions
