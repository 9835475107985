// ===== VARS TO SWITCH FOR MAIN vs RINKEBY DEPLOYS [K-COMPOSISTIONS] >>>>>
export const ETHERSCAN_BASE_URL = 'http://etherscan.io/'
// export const ETHERSCAN_BASE_URL = 'http://rinkeby.etherscan.io/'

export const OPENSEA_BASE_URL = 'https://opensea.io/'
// export const OPENSEA_BASE_URL = 'https://rinkeby.opensea.io/'

export const INFURA_API = 'https://mainnet.infura.io/v3/e7137105bf954ca3bbef3e37ec1a636f'
// export const INFURA_API = 'https://rinkeby.infura.io/v3/e7137105bf954ca3bbef3e37ec1a636f'

export const CONTRACT_ADDRESS = '0x3d0c4e9bde15f2681902f9f291b3c905b7ea46f9'
// ===== VARS TO SWITCH FOR MAIN vs RINKEBY DEPLOYS [K-COMPOSISTIONS] <<<<<

// ===== VARS [MUTATIONS] >>>>>
export const MUTATIONS_SVG_BASE_URL = 'https://gener8tive.io/mutations'
export const MUTATIONS_SHOWCASE_SVG_SIZE = '600px'
export const MUTATIONS_SUPPLY_SVG_SIZE = '275px'
export const MUTATIONS_FULL_SVG_SIZE = '600px'
export const MUTATIONS_METADATA_URL_BASE = 'https://api.gener8tive.io/mutations/metadata'
export const MUTATIONS_CONTRACT_ADDRESS = '0x1cb7176c90426ec53246f5b86b041913b3cc668e'

export const MUTATIONS_OPENSEA_COLLECTION_URL = OPENSEA_BASE_URL + 'collection/gener8tive-mutations'
// ===== VARS [MUTATIONS] <<<<<

// ===== VARS [TONES] >>>>>
export const TONES_OPENSEA_COLLECTION_URL = OPENSEA_BASE_URL + 'collection/gener8tive-tones'
export const TONES_OPENSEA_TOKEN_URL = OPENSEA_BASE_URL + 'assets/0xc410b0acced045a5b57ad235909ae6df1261bcc6'
// ===== VARS [TONES] <<<<<

// ===== VARS [METRO] >>>>>
export const METRO_SHOWCASE_PNG_SIZE = '400px'
export const MINT_STATUS_API = 'https://api.gener8tive.io/metro/mintstatus-v2'
export const METRO_CONTRACT_ADDRESS = '0xB71Efa3e73F61bCbCD6e5d5b66097145F3aa99A4'
export const METRO_ALLOCATION_CONTRACT_ADDRESS = '0x5388B0457745423471d717D525dfc7B0d8469fDF'
export const METRO_COLLECTION_URL = OPENSEA_BASE_URL + 'collection/gener8tive-metro'
// ===== VARS [METRO] <<<<<

export const REACT_GA_ENABLED = true

export const ETHERSCAN_TX_BASE_URL = ETHERSCAN_BASE_URL + 'tx/'
export const ETHERSCAN_ADDRESS_BASE_URL = ETHERSCAN_BASE_URL + 'address/'

// https://opensea.io/category/gener8tivek-compositions
export const OPENSEA_MARKET_URL = OPENSEA_BASE_URL + 'assets/gener8tivek-compositions'
export const OPENSEA_USER_BASE_URL = OPENSEA_BASE_URL + 'accounts/'
export const OPENSEA_COMPOSITION_BASE_URL = OPENSEA_BASE_URL + 'assets/'
export const OPENSEA_COLLECTION_URL = OPENSEA_BASE_URL + 'collection/gener8tive-k-compositions'

export const KCOMPS_SUPPLY_THUMBS_BASE_URL = 'https://gener8tive.io/svg-thumbs/'

export const KCOMPS_TO_SVG_SCRIPT_IPFS_CID = 'QmbCSvNeta4Qc1VKo9BQAybGwJ2Va2oQjS67F3vGim8szY'
export const KCOMPS_METADATA_URL_BASE = 'https://api.gener8tive.io/kcompositions/tokens/metadata'

export const AXIOS_CACHE_MAX_AGE = 15 * 60 * 1000

export const SHOWCASE_1_URL = 'https://gener8tive.mypinata.cloud/ipfs/QmYXZ9DBjH6GWzgFw9S7CGwbVjCtSARpsXoA8cwCyEiaXj'
export const SHOWCASE_2_URL = 'https://gener8tive.mypinata.cloud/ipfs/QmVbcXtPGwX6Y5MKgu3D3rwxZQzoXSk2ZdmCPYB9jPuCHH'
export const SHOWCASE_3_URL = 'https://gener8tive.mypinata.cloud/ipfs/QmPRXSH2ZCwYQ9MhW9saf2zMGKUUA7mH3MkGgcf1Jn7FFq'
export const SHOWCASE_4_URL = 'https://gener8tive.mypinata.cloud/ipfs/QmXY1VBdR35T1aRCtDdtVix8v41qVdPXhp1b6J9eNNN4XG'

export const SHOWCASE_1_COMPOSITION_ID = 8
export const SHOWCASE_2_COMPOSITION_ID = 43
export const SHOWCASE_3_COMPOSITION_ID = 0
export const SHOWCASE_4_COMPOSITION_ID = 21

export const SHOWCASE_ITEM_SIZE = 300
export const SHOWCASE_MULTIPLIER = 9

export const COMPOSITION_MULTIPLIER = 17
export const FULL_ITEM_SIZE = 566.67

export const CHARITY_NAME = 'GiveDirectly'
export const CHARITY_URL = 'https://givedirectly.org'

export const IPFS_GATEWAY_BASE_URL = 'https://gener8tive.mypinata.cloud/ipfs'