import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import createRootReducer from './modules'

import createSagaMiddleware from 'redux-saga'

// import availableTokensDataSaga from './sagas/availableTokensDataSaga'
// import myTokensDataSaga from './sagas/myTokensDataSaga'
// import allTokensDataSaga from './sagas/allTokensDataSaga'
// import tokenDetailsSaga from './sagas/tokenDetailsSaga'
// import tokenProvenanceSaga from './sagas/tokenProvenanceSaga'

export const history = createHistory()

const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  applyMiddleware(sagaMiddleware),
  ...enhancers
)

export default createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
)

// sagaMiddleware.run(availableTokensDataSaga)
// sagaMiddleware.run(myTokensDataSaga)
// sagaMiddleware.run(allTokensDataSaga)
// sagaMiddleware.run(tokenDetailsSaga)
// sagaMiddleware.run(tokenProvenanceSaga)
